export default {
  hero: {
    left: {
      title: 'New to Streaming',
      subtitle:
        'With so many TV streaming services available, choosing the right ones can be a challenge. Simply answer a few questions, select your favorite channels, and get personalized recommendations that meet your streaming needs at the best prices.',
    },
    right: {
      title: 'Already Streaming',
      subtitle:
        'Did you know there are over 130 unique streaming services beyond household names like Hulu, Netflix, and Amazon Prime? Explore our marketplace to build your personalized TV and streaming package!',
    },
  },
  cordCutting: {
    title: 'Thinking of Cutting the Cord?',
    subtitle:
      'All you need is a high-speed internet connection, a SmartTV or streaming device, and streaming channels!',
    features: {
      item1: {
        title: 'Get Fast and Reliable Internet',
        subtitle:
          'Sign up for our internet service! Our internet experience is designed for streaming, with symmetrical upload and download speeds.',
      },
      item2: {
        title: 'Select Streaming Services',
        subtitle:
          'Use the MyBundleTV tool to find streaming options that meet your viewing needs at the best possible prices.',
      },
    },
  },
  appSection: {
    title: 'Find Movies & Shows',
    subtitle:
      'Struggle to discover new movies & shows and where to find them? Level up your experience with the MyBundle App, \n' +
      'all at your fingertips!',
    features: {
      item1: 'Explore top movies & TV shows across various streaming services in one place',
      item2: 'Generate multiple watchlists and share them with your family and friends',
      item3: 'Get and give recommendations from your friends on what to watch next',
      item4: 'Quickly get and give recommendations on what to watch next',
    },
  },
  questions: {
    title: 'FAQs',
    subtitle: '',
    question1: {
      question: 'What is Streaming TV? ',
      answer:
        'Put simply, streaming TV is entertainment transmitted over the internet instead of a cable or satellite box. With our reliable internet, you can stream your favorite TV channels, shows, movies, documentaries, and more – live or on demand. Using a streaming device or smart TV, you can watch services on your TV, or you can stream them on your computer or mobile devices. ',
    },
    question2: {
      question: 'What is MyBundle? ',
      answer:
        "We've partnered with MyBundle to help simplify your streaming TV experience because we know it can be confusing. MyBundle's suite of free tools help you eliminate all the work needed to find the streaming services that have the content you actually want. Whether it's finding a replacement for cable or satellite with the channels you need, discovering new streaming services that match your interests, or deciding what to watch next – MyBundle can help. (And save you hundreds of dollars and hours in the process!)",
    },
    question3: {
      question: 'What’s a Smart TV or streaming device? Do I need one? ',
      answer:
        'If you’d like to enjoy streaming content on your TV, you’ll need a Smart TV or streaming device. A lot of newer TVs come equipped Smart TV capabilities, which allow you to connect your TV to your Wi-Fi network or modem and access streaming service apps. Alternatively, a streaming device such as a Roku, Amazon’s Firestick, Apple TV, or Google Chromecast, will turn your TV into a Smart TV. With a simple interface, streaming devices allow you to use streaming apps and watch your favorite shows and movies. ',
    },
    question4: {
      question: 'How does Find My Bundle work?',
      answer:
        'Answer a few questions about your lifestyle and viewing preferences. Input your zip code. Select your must-have live TV channels, and Find My Bundle will present the best streaming TV service(s) for you to replace cable TV. Enter your email if you’d like a copy of your results, or go ahead and sign-up for the service(s) that best matches your needs and budget right there. ',
    },
    question5: {
      question: 'Why should I switch to Streaming? ',
      answer:
        'Cable TV is constantly getting more expensive and often includes more channels than you actually watch. With Streaming, you can enjoy the same content and channels you’re used to in a more affordable package. Beyond live TV, with streaming you can access low-cost or free streaming services that offer movies, shows, and documentaries that match your interests. Streaming also provides you the ability to watch your favorite content wherever and however you want.  ',
    },
  },
  streamingSupport: {
    title: 'Streaming Support',
    subtitle:
      'If you encounter any issues with streaming, don’t hesitate to contact the streaming service for support!',
  },
};
