import produce from 'immer';

// CONSTANTS
import { AnimCardPosition } from '@/constants/streamingRewards';

import {
  getStreamingRewards,
  clearStreamingRewards,
  updateStreamingRewards,
  claimStreamingRewards,
  getVirtualCardExpandedData,
  checkStreamingRewards,
  clearVirtualCardExpandedData,
  getSRClaimInstruction,
  setPlayAnimation,
  setAnimCardPosition,
  getImageApps,
  getSRAppsSearch,
  clearSRAppsSearch,
  completeRedeemSR,
} from '../actions/streamingRewards';

const initialState = {
  data: {},
  claimData: {},
  instrData: {},
  imageApps: [],
  playAnimation: false,
  animCardPosition: AnimCardPosition.TOP,
  search: {
    data: [],
    string: '',
  },
  loadings: {
    data: true,
    claim: false,
    expand: false,
    check: false,
    instrData: false,
    imageApps: false,
    search: false,
    completeRedeem: false,
  },
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    // GET STREAMING REWARDS DATA
    case getStreamingRewards.types.START:
      state.data = initialState.data;
      state.loadings.data = true;
      return state;

    case getStreamingRewards.types.SUCCESS:
      state.data = action.payload.data;
      state.loadings.data = false;
      return state;

    case getStreamingRewards.types.FAIL:
      state.loadings.data = false;
      return state;

    // UPDATE STREAMING REWARDS DATA
    case updateStreamingRewards.types.INIT:
      state.data = { ...state.data, ...action.payload.data };
      return state;

    // CLEAT STREAMING REWARDS DATA
    case clearStreamingRewards.types.INIT:
      state.data = initialState.data;
      state.claimData = initialState.claimData;
      return state;

    // GET CLAIMED STREAMING REWARDS DATA
    case claimStreamingRewards.types.START:
      state.loadings.claim = true;
      return state;

    case claimStreamingRewards.types.SUCCESS:
      state.data = { ...state.data, ...action.payload.data, step: 3 };
      state.loadings.claim = false;
      return state;

    case claimStreamingRewards.types.FAIL:
      state.loadings.claim = false;
      return state;

    // CHECK STREAMING REWARDS DATA
    case checkStreamingRewards.types.START:
      state.claimData = initialState.claimData;
      state.loadings.check = true;
      return state;

    case checkStreamingRewards.types.SUCCESS:
    case checkStreamingRewards.types.FAIL:
      state.data = { ...state.data, ...action.payload.data };
      state.claimData = { ...state.claimData, ...action.payload.data };
      state.loadings.check = false;
      return state;

    // GET VIRTUAL CARD EXPANDED DATA
    case getVirtualCardExpandedData.types.START:
      state.loadings.expand = true;
      return state;

    case getVirtualCardExpandedData.types.SUCCESS:
      state.data = { ...state.data, ...action.payload.data };
      state.loadings.expand = false;
      return state;

    case getVirtualCardExpandedData.types.FAIL:
      state.loadings.expand = false;
      return state;

    // CLEAR VIRTUAL CARD EXPANDED DATA
    case clearVirtualCardExpandedData.types.INIT:
      state.data.expand = {};
      return state;

    // GET STREAMING REWARDS DATA
    case getSRClaimInstruction.types.START:
      state.loadings.instrData = true;
      return state;

    case getSRClaimInstruction.types.SUCCESS:
      state.instrData = action.payload.data;
      state.loadings.instrData = false;
      return state;

    case getSRClaimInstruction.types.FAIL:
      state.loadings.instrData = false;
      return state;

    // SET PLAY ANIMATION
    case setPlayAnimation.types.INIT:
      state.playAnimation = action.payload.state;
      return state;

    // SET ANIMATION CARD POSITION
    case setAnimCardPosition.types.INIT:
      state.animCardPosition = action.payload.position;
      return state;

    // GET IMAGE APPS
    case getImageApps.types.START:
      state.loadings.imageApps = true;
      return state;

    case getImageApps.types.SUCCESS:
      state.imageApps = action.payload.data;
      state.loadings.imageApps = false;
      return state;

    case getImageApps.types.FAIL:
      state.loadings.imageApps = false;
      return state;

    // GET SEARCH
    case getSRAppsSearch.types.START:
      state.search.string = action.payload.params.search;
      state.loadings.search = true;
      return state;

    case getSRAppsSearch.types.SUCCESS:
      state.search.data = action.payload.data;
      state.loadings.search = false;
      return state;

    case getSRAppsSearch.types.FAIL:
      state.loadings.search = false;
      return state;

    // CLEAR SEARCH
    case clearSRAppsSearch.types.INIT:
      state.search = initialState.search;
      return state;

    // COMPLETE REDEEM
    case completeRedeemSR.types.START:
      state.loadings.completeRedeem = true;
      return state;

    case completeRedeemSR.types.SUCCESS:
    case completeRedeemSR.types.FAIL:
      state.loadings.completeRedeem = false;
      return state;

    default:
      return state;
  }
});

export default reducer;
